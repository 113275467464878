import React from 'react';
import { Link, List, PageHero, PageWithSubNav, Paragraph, Section, SectionSubhead } from 'docComponents';
import pageHeroData from '../../../../data/pages/words.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Calls to Action" subnav="words">
      <PageHero heroData={pageHeroData} tierTwo="Content Elements" tierThree="Calls to Action" />
      <Section>
        <Paragraph>
          A common pattern on screens includes a title/headline, a few interactive elements or pieces of content, and a
          button CTA. <strong>The microcopy for the title and button should be congruent.</strong>
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Title-to-CTA Congruence</SectionSubhead>
        <List type="unordered">
          <li>If the title asks a question, the button should answer (like an actual conversation).</li>
          <li>Key verbs and nouns should appear in both the title and CTA.</li>
          <li>
            Remain <Link href="/words/writing-guidelines/your-my-neutral">neutral</Link> wherever possible.
          </li>
        </List>
        <SectionSubhead>Examples</SectionSubhead>
        <Paragraph>
          <strong>Remove Members Who Have Left the Team</strong>
          <br />
          <em>Remove 40 Selected Members</em>
        </Paragraph>
        <Paragraph>
          <strong>Want to Clean Your Library?</strong>
          <br />
          <em>Yes, Start Cleaning</em>
        </Paragraph>
        <Paragraph>
          <strong>Register with Your Team</strong>
          <br />
          <em>Register Now</em>
        </Paragraph>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
